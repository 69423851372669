import React, { useState } from "react";
import "./style.css";
import TypeIcon from "../../svgs/TypeIcons";

const GeneratorButton = ({ text, handleClick, active }) => {
  const [isHovered, setIsHovered] = useState(false);

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  return (
    <div
      className={
        (active ? "generatorButtonSelected" : "generatorButton") + " selectNone"
      }
      onClick={() => handleClick(text)}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <TypeIcon
        type={text}
        color={active ? "#F9F5EC" : isHovered ? "#A78F6E" : "#54422E"}
      />
      <span
        className={
          (active ? "generatorLabelSelected" : "generatorLabel") + " selectNone"
        }
        style={{ color: isHovered && !active && "#A78F6E" }}
      >
        {text.charAt(0).toUpperCase() + text.slice(1)}
      </span>
    </div>
  );
};

export default GeneratorButton;
