import { useEffect, useRef, useState } from "react";
import "./style.css";

import { CSSTransition } from "react-transition-group";

import SectionLabel from "../../../components/SectionLabel";
import Slider from "../../../components/Slider";
import { FEATURE_KEYS, GENERATOR } from "../../../constants";
import PatreonFeatureLabel from "../../../components/PatreonFeatureLabel";

const MAP_SIZE_OPTIONS = {
  SMALL: "Small",
  MEDIUM: "Medium",
  LARGE: "Large",
  HUGE: "Huge",
  CUSTOM: "Custom",
};

const MapSize = ({
  mapSizeDisabled,
  mapSizeMin,
  mapSizeMax,
  panelState,
  setPanelState,
  handleMapSizeChange,
  handleMapSizeBlur,
  mapSizeRef,
}) => {
  const [activeButton, setActiveButton] = useState(MAP_SIZE_OPTIONS.SMALL);
  const [showMapSlider, setShowMapSlider] = useState(false);

  const mapSliderRef = useRef(null);

  useEffect(() => {
    setActiveButton(MAP_SIZE_OPTIONS.SMALL);
    setShowMapSlider(false);
  }, [panelState.generator]);

  const handleClick = (button) => {
    if (
      mapSizeDisabled &&
      button !== MAP_SIZE_OPTIONS.SMALL &&
      button !== MAP_SIZE_OPTIONS.MEDIUM
    ) {
      return;
    }

    setShowMapSlider(button === MAP_SIZE_OPTIONS.CUSTOM);
    setActiveButton(button);
    if (button !== MAP_SIZE_OPTIONS.CUSTOM) {
      setPanelState({
        ...panelState,
        mapSize: getMapSizeValueFromLabel(button),
      });
    }
  };

  const getMapSizeLabelFromValue = () => {
    const value = panelState.mapSize;

    if (value >= 2 && value <= 3) {
      return MAP_SIZE_OPTIONS.SMALL;
    } else if (value >= 4 && value <= 5) {
      return MAP_SIZE_OPTIONS.MEDIUM;
    } else if (value >= 6 && value <= 7) {
      return MAP_SIZE_OPTIONS.LARGE;
    } else if (value >= 8) {
      return MAP_SIZE_OPTIONS.HUGE;
    }
  };

  const getMapSizeValueFromLabel = (button) => {
    if (button === MAP_SIZE_OPTIONS.SMALL) {
      return Math.floor(Math.random() * (3 - 2 + 1)) + 2;
    } else if (button === MAP_SIZE_OPTIONS.MEDIUM) {
      return Math.floor(Math.random() * (5 - 4 + 1)) + 4;
    } else if (button === MAP_SIZE_OPTIONS.LARGE) {
      return Math.floor(Math.random() * (7 - 6 + 1)) + 6;
    } else if (button === MAP_SIZE_OPTIONS.HUGE) {
      return Math.floor(Math.random() * (9 - 8 + 1)) + 8;
    }
  };

  return (
    <div
      className={`sectionContainer`}
      style={{
        paddingBottom: 0,
      }}
      ref={mapSizeRef}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          paddingBottom: 0,
          width: "100%",
        }}
      >
        <div
          style={{
            paddingBottom: ".5em",
            paddingRight: 2,
          }}
        >
          <SectionLabel text="Map Size" featureKey={FEATURE_KEYS.MAP_SIZE} />
          <div className="mapSizeButtonContainer" style={{ marginLeft: 4 }}>
            <div
              className={`mapSizeButton ${
                activeButton === MAP_SIZE_OPTIONS.SMALL && "mapSizeButtonActive"
              }`}
              onClick={() => handleClick(MAP_SIZE_OPTIONS.SMALL)}
            >
              {MAP_SIZE_OPTIONS.SMALL}
            </div>
            <div
              className={`mapSizeButton ${
                activeButton === MAP_SIZE_OPTIONS.MEDIUM &&
                "mapSizeButtonActive"
              }`}
              onClick={() => handleClick(MAP_SIZE_OPTIONS.MEDIUM)}
            >
              {MAP_SIZE_OPTIONS.MEDIUM}
            </div>
          </div>
        </div>
        <div
          style={{
            width: "100%",
            paddingBottom: ".5em",
            paddingLeft: 2,
          }}
          className={mapSizeDisabled ? "disabledSection" : ""}
        >
          <div
            style={{
              paddingBottom: 2,
              paddingTop: 10,
            }}
          >
            <div style={{ display: "flex", justifyContent: "flex-end" }}>
              <PatreonFeatureLabel
                featureKey={FEATURE_KEYS.MAP_SIZE}
                hide={!mapSizeDisabled}
              />
            </div>
          </div>
          <div className="mapSizeButtonContainer">
            <div
              className={`mapSizeButton ${
                mapSizeDisabled && "mapSizeButtonDisabled"
              } ${
                activeButton === MAP_SIZE_OPTIONS.LARGE && "mapSizeButtonActive"
              }`}
              onClick={() => handleClick(MAP_SIZE_OPTIONS.LARGE)}
            >
              {MAP_SIZE_OPTIONS.LARGE}
            </div>
            <div
              className={`mapSizeButton  ${
                mapSizeDisabled && "mapSizeButtonDisabled"
              } ${
                activeButton === MAP_SIZE_OPTIONS.HUGE && "mapSizeButtonActive"
              }`}
              onClick={() => handleClick(MAP_SIZE_OPTIONS.HUGE)}
            >
              {MAP_SIZE_OPTIONS.HUGE}
            </div>
            <div
              className={`mapSizeButton ${
                mapSizeDisabled && "mapSizeButtonDisabled"
              } ${
                activeButton === MAP_SIZE_OPTIONS.CUSTOM &&
                "mapSizeButtonActive"
              }`}
              onClick={() => handleClick(MAP_SIZE_OPTIONS.CUSTOM)}
            >
              {MAP_SIZE_OPTIONS.CUSTOM}
            </div>
          </div>
        </div>
      </div>
      {/* Custom Slider */}
      <CSSTransition
        in={showMapSlider}
        nodeRef={mapSliderRef}
        timeout={200}
        classNames="fade"
        unmountOnExit
      >
        <div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              marginTop: 10,
              alignItems: "center",
            }}
            ref={mapSliderRef}
          >
            <Slider
              min={mapSizeMin}
              max={mapSizeMax}
              step={1}
              ticks={[mapSizeMin, mapSizeMax]}
              value={panelState.mapSize}
              onChange={handleMapSizeChange}
            />
            <input
              type="number"
              max={mapSizeMax}
              min={mapSizeMin}
              value={panelState.mapSize}
              onChange={(e) => handleMapSizeChange(Number(e.target.value))}
              onBlur={handleMapSizeBlur}
              style={{ height: 20, width: 20, marginLeft: 20, marginTop: 5 }}
              className="no-arrows"
            />
            <label className="smallLabel" style={{ marginLeft: 10 }}>
              {getMapSizeLabelFromValue()}
            </label>
          </div>
        </div>
      </CSSTransition>
    </div>
  );
};

export default MapSize;
