import {
  GENERATOR,
  MAP_SIZE_MAX,
  FEATURE_TIERS,
  PATREON_TIERS,
  FEATURE_KEYS,
} from "./constants";

export const getMapSizeMax = (panelState) => {
  switch (panelState.generator) {
    case GENERATOR.DUNGEON:
      return MAP_SIZE_MAX[panelState.visuals.theme] || 16;
    case GENERATOR.ROAD:
      return 9;
    default:
      return 16;
  }
};

export const getFeatureSetting = (featureKey, tier) => {
  return FEATURE_TIERS[featureKey] &&
    FEATURE_TIERS[featureKey][tier] !== undefined
    ? FEATURE_TIERS[featureKey][tier]
    : null;
};

export const getFirstAvailableTier = (featureKey) => {
  if (!FEATURE_TIERS[featureKey]) return "Patreon";

  const tierValues = Object.entries(FEATURE_TIERS[featureKey]);
  const firstAvailableTier = tierValues.find(
    ([tier, value]) => value === true
  )[0];

  if (firstAvailableTier === PATREON_TIERS.GOLD) return PATREON_TIERS.FOUNDER;

  return firstAvailableTier;
};

export const isAllThemeOptionsDisabled = (options, userTier) =>
  options === undefined
    ? false
    : options.every(
        (option) =>
          !FEATURE_TIERS[FEATURE_KEYS[GENERATOR.DUNGEON].AVAILABLE_THEMES][
            userTier
          ].includes(option)
      );
