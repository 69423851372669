import { getFirstAvailableTier } from "../../utils";
import PatreonFeatureLabel from "../PatreonFeatureLabel";

const SectionLabel = ({ text, disabled, small, featureKey }) => {
  return (
    <div className="sectionLabelContainer">
      <label
        className={`${small ? "smallLabel" : "label"} ${
          disabled && "disabledLabel"
        }`}
      >
        {text}
      </label>
      {disabled && <PatreonFeatureLabel featureKey={featureKey} />}
    </div>
  );
};

export default SectionLabel;
