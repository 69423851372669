import React from "react";

import "./style.css";
import { getFirstAvailableTier } from "../../utils";

const Dropdown = ({
  options,
  optionLabels,
  value,
  onChange,
  disabled,
  featureKey,
}) => {
  return (
    <div>
      <select
        className="visualsDropdown"
        value={value}
        onChange={(e) => onChange(e.target.value)}
        style={disabled ? { opacity: 0.5 } : null}
      >
        {options.map((option, index) => (
          <option
            key={index}
            value={option}
            disabled={disabled}
            title={
              disabled
                ? `Available in ${getFirstAvailableTier(featureKey)
                    .toLowerCase()
                    .replace(/\b\w/g, (l) => l.toUpperCase())} Patreon Tier`
                : ""
            }
            className="visualsDropdownOption"
          >
            {optionLabels[index]}
            {disabled && index !== 0
              ? ` (${getFirstAvailableTier(featureKey)
                  .toLowerCase()
                  .replace(/\b\w/g, (l) => l.toUpperCase())} Tier)`
              : ""}
          </option>
        ))}
      </select>
    </div>
  );
};

export default Dropdown;
