import { useEffect, useRef, useState } from "react";
import "./App.css";

import { CSSTransition } from "react-transition-group";

import LeftPanel from "./sections/LeftPanel";
import MapSection from "./sections/MapSection";
import RightBar from "./sections/RightBar";
import DMInfoPane from "./sections/DMInfoPane";
import {
  checkAuth,
  generateMap,
  getMapUrl,
  getPatreonTier,
  login,
  logout,
} from "./api";

const App = () => {
  const [infoActive, setInfoActive] = useState(false);
  const [emailActive, setEmailActive] = useState(false);
  const [helperEnabled, setHelperEnabled] = useState(false);
  const [showHelper, setShowHelper] = useState(false);
  const [playerMapUrl, setPlayerMapUrl] = useState("");
  const [dmMapUrl, setDmMapUrl] = useState("");
  const [dmPdfUrl, setDmPdfUrl] = useState("");
  const [mapData, setMapData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [user, setUser] = useState(null);

  const generateHelperRef = useRef(null);

  const queryParams = new URLSearchParams(window.location.search);
  const emailMode = queryParams.get("email");

  const rightPaneOpen = infoActive || emailActive;

  const handleLogin = () => {
    login();
  };

  const handleLogout = () => {
    const confirmLogout = window.confirm(
      "Log out of Mythical Maps Infinity?\n\nPress OK to log out, or Cancel to stay logged in."
    );
    if (!confirmLogout) return;

    logout();
  };

  useEffect(() => {
    setShowHelper(helperEnabled);
  }, [helperEnabled]);

  // Check if the user is authenticated when the app loads
  useEffect(() => {
    checkAuth()
      .then((data) => {
        if (data.isAuthenticated) {
          getPatreonTier()
            .then(({ tier }) => {
              setUser({ ...data.user, tier });
              setIsAuthenticated(true);
              setHelperEnabled(false);
            })
            .catch((error) => {
              console.error("Error getting Patreon tier:", error);
              alert(
                "You need to be a subscriber to the Arcane Collector Patreon to gain full access to Mythical Maps Infinity. Please subscribe and refresh the page to gain full access."
              );
            });
        } else {
          setHelperEnabled(true);
        }
      })
      .catch((error) => {
        console.error("Error checking authentication:", error);
      });
  }, []);

  const handleGenerateMap = async (state) => {
    setHelperEnabled(false);

    if (loading) return;

    // console.log("formState", state);
    setLoading(true);
    setError(null);
    setMapData([]);
    setDmMapUrl("");
    setPlayerMapUrl("");
    setDmPdfUrl("");

    try {
      const mapData = await generateMap(state);

      // console.log("MAPDATA", mapData);

      const playerMapUrl = await getMapUrl(mapData?.filenames?.player);
      setPlayerMapUrl(playerMapUrl);

      if (mapData?.filenames?.dm) {
        const dmMapUrl = await getMapUrl(mapData?.filenames?.dm);
        setDmMapUrl(dmMapUrl);
      }

      if (mapData?.filenames?.pdf) {
        const dmPdfUrl = await getMapUrl(mapData?.filenames?.pdf);
        setDmPdfUrl(dmPdfUrl);
      }

      setMapData(mapData);
      if (mapData?.filenames?.dm) {
        setEmailActive(false);
      }

      setInfoActive(true);
    } catch (error) {
      setError(error.toString());
    } finally {
      setLoading(false);
    }
  };

  const handleInfoClick = () => {
    if (!infoActive) {
      setEmailActive(false);
    }

    setInfoActive(!infoActive);
  };

  const handleEmailClick = () => {
    if (!emailActive) {
      setInfoActive(false);
    }

    setEmailActive(!emailActive);
  };

  useEffect(() => {
    const userAgent = navigator.userAgent || navigator.vendor || window.opera;
    console.log("userAgent", userAgent);
    if (
      /android|ipad|iphone/.test(userAgent.toLowerCase()) &&
      !window.MSStream
    ) {
      window.location.href =
        "https://arcanecollector.com/mythical-maps-infinity-information/";
    }
  }, []);

  return (
    <div className="App">
      {isAuthenticated && (
        <div className={`loginStatus ${rightPaneOpen ? "center" : ""}`}>
          <div>
            Logged in as: {user?.displayName} (
            <a
              href="javascript:void(0)"
              onClick={handleLogout}
              style={{ zIndex: 1 }}
            >
              Logout
            </a>
            )
          </div>
          <div>Patreon Tier: {user?.tier}</div>
        </div>
      )}
      <div className="body">
        <LeftPanel
          generateMap={handleGenerateMap}
          loading={loading}
          isAuthenticated={isAuthenticated}
          handleLogin={handleLogin}
          userTier={user?.tier || "FREE"}
          setShowHelper={setShowHelper}
        />
        <MapSection
          rightPaneOpen={rightPaneOpen}
          playerMapUrl={playerMapUrl}
          dmMapUrl={dmMapUrl}
          dmPdfUrl={dmPdfUrl}
          mapData={mapData}
          loading={loading}
          error={error}
        />
        <div className={`rightContainer ${rightPaneOpen ? "active" : ""}`}>
          <RightBar
            infoActive={infoActive}
            showEmail={emailMode}
            emailActive={emailActive}
            handleInfoClick={handleInfoClick}
            handleEmailClick={handleEmailClick}
          />
          <DMInfoPane mapData={mapData} emailActive={emailActive} />
        </div>
      </div>
      <CSSTransition
        in={helperEnabled && showHelper}
        timeout={1000}
        nodeRef={generateHelperRef}
        classNames="fadeLong"
        unmountOnExit
      >
        <div ref={generateHelperRef}>
          <label className="generateHelperLabel">
            Click <span style={{ fontWeight: "bold" }}>Generate Map</span> to
            <br />
            get started
          </label>
          <img
            src="images/generate-arrow.png"
            className="generateHelperArrow"
          />
        </div>
      </CSSTransition>
      <div className="mobile-message">
        This application is not available on mobile devices at this time. To
        learn more about the App please visit{" "}
        <a href="https://arcanecollector.com/mythical-maps-infinity-information/">
          https://arcanecollector.com/mythical-maps-infinity-information/
        </a>
      </div>
    </div>
  );
};

export default App;
