import React, { useState } from "react";

import "./style.css";

const Slider = ({ min, max, step, ticks, value, onChange, disabled }) => {
  const [isHovered, setIsHovered] = useState(false);

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  const datalistOptions = ticks.map((tick, i) => <span key={i}>{tick}</span>);

  let sliderColorLeft = "#007A79";
  let sliderColorRight = "#C3AE8F";

  if (disabled) {
    sliderColorLeft = "#C3AE8F";
  } else if (isHovered) {
    sliderColorLeft = "#00A89F";
  }

  const sliderStyle = {
    background: `linear-gradient(to right, ${sliderColorLeft} ${
      ((value - min) / (max - min)) * 100
    }%, ${sliderColorRight} ${((value - min) / (max - min)) * 100}%)`,
  };

  return (
    <div className="sliderContainer">
      <input
        type="range"
        min={min}
        max={max}
        step={step}
        className={`slider ${disabled && "disabled"}`}
        onChange={(e) => onChange(Number(e.target.value))}
        value={value}
        style={sliderStyle}
        disabled={disabled}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      />
      <div className="sliderticks selectNone">{datalistOptions}</div>
    </div>
  );
};

export default Slider;
