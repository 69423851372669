import React from "react";
import { getFirstAvailableTier } from "../../utils";

const PatreonFeatureLabel = ({ featureKey, hide }) => {
  return (
    <div
      className="patreonLabelContainer"
      style={{ visibility: hide ? "hidden" : "show" }}
      onClick={() =>
        window.open(
          "https://www.patreon.com/arcanecollector/membership",
          "_blank"
        )
      }
    >
      <img
        src="./images/patreon-icon-disabled.png"
        alt="Patreon Logo"
        className="patreonSmallImg"
      />
      <label className="patreonLabel">
        {getFirstAvailableTier(featureKey)} Feature
      </label>
    </div>
  );
};

export default PatreonFeatureLabel;
