import React, { useEffect, useState } from "react";
import "./styles.css";

import { InfinitySpin } from "react-loader-spinner";

import Map from "../../components/Map";

const MAP_TYPE = {
  PLAYER: "player",
  DM: "dm",
};

const MapSection = ({
  rightPaneOpen,
  playerMapUrl,
  dmMapUrl,
  dmPdfUrl,
  mapData,
  loading,
  error,
}) => {
  const [activeMap, setActiveMap] = useState(MAP_TYPE.PLAYER);
  const [showTooltip, setShowTooltip] = useState(false);
  const [zoom, setZoom] = useState(1.0);

  useEffect(() => {
    setActiveMap(MAP_TYPE.PLAYER);
    setZoom(1.0);
  }, [loading]);

  const handleZoom = (value) => {
    // Ensure the zoom value stays within the range of 0.1 (10%) to 3.0 (300%)
    if (value < 0.1) {
      value = 0.1;
    } else if (value > 3.0) {
      value = 3.0;
    }

    setZoom(value);
  };

  const handleDownloadMap = () => {
    const link = document.createElement("a");
    link.href = activeMap === MAP_TYPE.PLAYER ? playerMapUrl : dmMapUrl;
    link.setAttribute("download", `${mapData.filenames[MAP_TYPE.PLAYER]}`);
    link.style.display = "none";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const handleDownloadPDF = () => {
    const link = document.createElement("a");
    link.href = dmPdfUrl;
    link.setAttribute("download", `${mapData.filenames.pdf}`);
    link.style.display = "none";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const handleCopyMap = () => {
    const image = new Image();
    image.crossOrigin = "anonymous";
    image.onload = () => {
      const canvas = document.createElement("canvas");
      canvas.width = image.width;
      canvas.height = image.height;
      const context = canvas.getContext("2d");
      context.drawImage(image, 0, 0);
      canvas.toBlob((blob) => {
        const item = new ClipboardItem({ "image/png": blob });
        navigator.clipboard
          .write([item])
          .then(() => {
            console.log("Image copied to clipboard successfully!");
            setShowTooltip(true);
            setTimeout(() => setShowTooltip(false), 2000);
          })
          .catch((err) => {
            console.error("Failed to copy image to clipboard: ", err);
          });
      }, "image/png");
    };
    image.src = activeMap === MAP_TYPE.PLAYER ? playerMapUrl : dmMapUrl;
  };

  return (
    <div
      className="middleContainer"
      style={{ marginRight: rightPaneOpen ? "300px" : "0" }}
    >
      {dmMapUrl && !loading && (
        <div className="mapToggleContainer">
          <div
            className={`playerToggle mapToggle ${
              activeMap === MAP_TYPE.PLAYER && "mapToggleActive"
            }`}
            onClick={() => setActiveMap(MAP_TYPE.PLAYER)}
          >
            Player Map
          </div>
          <div
            className={`dmToggle mapToggle ${
              activeMap === MAP_TYPE.DM && "mapToggleActive"
            }`}
            onClick={() => setActiveMap(MAP_TYPE.DM)}
          >
            DM Map
          </div>
        </div>
      )}
      {playerMapUrl && !loading ? (
        <Map
          src={activeMap === MAP_TYPE.PLAYER ? playerMapUrl : dmMapUrl}
          zoom={zoom}
          setZoom={setZoom}
          dmMapUrl={dmMapUrl}
          rightPaneOpen={rightPaneOpen}
        />
      ) : (
        <div className="placeholder">
          {!playerMapUrl && !loading && (
            <div className="logoContainer">
              <img src="images/large-logo-1.svg" className="overlay-image" />
              <img src="images/large-logo-2.svg" className="overlay-image" />
              <img src="images/large-logo-3.svg" className="overlay-image" />
            </div>
          )}
          {error && (
            <div
              style={{ color: "#ED766F", fontFamily: "Lato", marginTop: 20 }}
            >
              {error}
            </div>
          )}
          {loading && (
            <InfinitySpin
              visible={loading}
              width="200"
              color="#007A79"
              ariaLabel="infinity-spin-loading"
            />
          )}
        </div>
      )}

      {playerMapUrl && !loading && (
        <div className="mapFooterContainer">
          <div />
          <div
            className={`zoomBar ${
              rightPaneOpen
                ? dmPdfUrl
                  ? "hideWhenPDF"
                  : "hideWithoutPDF"
                : "hideButton"
            }`}
          >
            <div className="zoomBarFill" onClick={() => handleZoom(zoom - 0.1)}>
              <img src="images/minus.svg" alt="minus" />
            </div>
            <div className="zoomBarLabel">
              {((Math.round(zoom * 10) / 10) * 100).toFixed(0)}%
            </div>
            <div className="zoomBarFill" onClick={() => handleZoom(zoom + 0.1)}>
              <img src="images/plus.svg" alt="plus" />
            </div>
          </div>
          <div className={`mapButtonContainer`} style={{ marginRight: 20 }}>
            <div
              className="mapButtonTooltipContainer"
              style={{ height: "100%" }}
              onClick={handleCopyMap}
            >
              {showTooltip && (
                <div className="tooltip smallLabel fade-in-up">Map copied!</div>
              )}
              <div
                className={`mapButton ${
                  rightPaneOpen
                    ? dmPdfUrl
                      ? "hideWhenPDF"
                      : "hideWithoutPDF"
                    : "hideButton"
                }`}
              >
                <img src="images/clipboard.svg" alt="clipboard" />
                <span className="mapButtonLabel">Copy Map</span>
              </div>
            </div>
            <img src="images/vertical-stroke.svg" alt="vertical stroke" />
            <div className="mapButton" onClick={handleDownloadMap}>
              <img src="images/download-arrow.svg" alt="download icon" />
              <span className="mapButtonLabel">Map</span>
            </div>
            {dmPdfUrl && (
              <img src="images/vertical-stroke.svg" alt="vertical stroke" />
            )}
            {dmPdfUrl && (
              <div className="mapButton" onClick={handleDownloadPDF}>
                <img src="images/download-arrow.svg" alt="download icon" />
                <span className="mapButtonLabel">PDF Guide</span>
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default MapSection;
