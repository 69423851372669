import { useState } from "react";
import ReactSwitch from "react-switch";

const Switch = ({ checked, onChange, disabled }) => {
  const [isHovered, setIsHovered] = useState(false);

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  let onColor = "#007A79";
  let offColor = "#8B7355";

  if (isHovered) {
    onColor = "#00A89F";
    offColor = "#B59E7E";
  }

  return (
    <label onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
      <ReactSwitch
        onColor={disabled ? "#C3AE8F" : onColor}
        offColor={disabled ? "#C3AE8F" : offColor}
        offHandleColor={disabled ? "#EDE2CD" : undefined}
        onHandleColor={disabled ? "#EDE2CD" : undefined}
        disabled={disabled}
        uncheckedIcon={false}
        checkedIcon={false}
        checked={checked}
        onChange={onChange}
      />
    </label>
  );
};

export default Switch;
